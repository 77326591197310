import React from 'react'

import { AppShell, Box, Center, Text, Space } from '@mantine/core'
import { useLocation } from 'react-router-dom'

import Header from './Header';
import { IPL_PLAYERS, T20_PLAYERS } from '../Players';


const CommonPlayers = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const format = queryParams.get('format');

    const getPlayerName = playerId => {
        const players = format === 'ipl' ? IPL_PLAYERS : T20_PLAYERS
        const entry = Object.entries(players).find(([key, value]) => value === playerId);
        return entry ? entry[0] : null;
    };

    return (
        <AppShell
            header={{ height: 60 }}
            padding="md"
        >
            <AppShell.Header>
                <Header removeHelp />
            </AppShell.Header>
            <AppShell.Main bg={'#F7F7F7'}>
                <Center>
                    <Box w={'100rem'}>
                        <Text fw={600}>{localStorage.getItem('cp_instruction')}</Text>
                        <Space h="sm" />
                        {JSON.parse(localStorage.getItem('cp_playerids')).map(item => {
                            return <>
                                <Text>► {getPlayerName(item)}</Text>
                                <Space h="sm" />
                            </>
                        })}
                    </Box>
                </Center>
            </AppShell.Main>
        </AppShell>
    );
}

export default CommonPlayers;
