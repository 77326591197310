import React from 'react';
import get from 'lodash.get';
import { Stack, Center, Group, Text, Button, Modal, Autocomplete, Loader, Space } from '@mantine/core';
import { useDisclosure, useCounter, useMediaQuery } from '@mantine/hooks'
import { IconXboxX } from '@tabler/icons-react';

import { IPL_PLAYERS, T20_PLAYERS } from '../Players';
import Grid from './Grid';
import Summary from './Summary';
import PreviousGrids from './PreviousGrids';
import { axiosInstance } from '../axios';
import { useAuth } from '../AuthContext';

const INIT_SELECTED_CHOICES = {
    "row_0_col_0": "",
    "row_0_col_1": "",
    "row_0_col_2": "",
    "row_1_col_0": "",
    "row_1_col_1": "",
    "row_1_col_2": "",
    "row_2_col_0": "",
    "row_2_col_1": "",
    "row_2_col_2": ""
}

const INIT_INCORRECT_ATTEMPTS = {
    "row_0_col_0": 0,
    "row_0_col_1": 0,
    "row_0_col_2": 0,
    "row_1_col_0": 0,
    "row_1_col_1": 0,
    "row_1_col_2": 0,
    "row_2_col_0": 0,
    "row_2_col_1": 0,
    "row_2_col_2": 0
}

const Main = ({ setInstruction, activeTab }) => {

    const { currentUser } = useAuth();

    const isSmallScreen = useMediaQuery('(max-width: 767px)');

    const [playerPickerOpened, { open: openPlayerPicker, close: closePlayerPicker }] = useDisclosure(false);
    const [summaryOpened, { open: openSummary, close: closeSummary }] = useDisclosure(false);
    const [previosGridListOpened, { open: openPreviousGridList, close: closePreviousGridList }] = useDisclosure(false);

    const [players, setPlayers] = React.useState(IPL_PLAYERS)
    const [count, handlers] = useCounter(12, { min: 0, max: 12 });
    const [gridScore, setGridScore] = React.useState(0)
    const [game, setGame] = React.useState(null)
    const [isLoadingGame, setIsLoadingGame] = React.useState(true)
    const [playerSelectionError, setPlayerSelectionError] = React.useState(false)
    const [selectedCell, setSelectedCell] = React.useState([null, null])
    const [grid, setGrid] = React.useState([['', '', ''], ['', '', ''], ['', '', '']])
    const [gridNo, setGridNo] = React.useState(0)
    const [previousGrids, setPreviousGrids] = React.useState([])
    const [selectedChoices, setSelectedChoices] = React.useState(INIT_SELECTED_CHOICES)

    const [incorrectAttempts, setIncorrectAttempts] = React.useState(INIT_INCORRECT_ATTEMPTS)

    const clickCell = (rowId, columnId) => {
        if (grid[rowId][columnId] !== '' || count === 0) return
        setPlayerSelectionError(false)
        setSelectedCell([rowId, columnId])
        openPlayerPicker()
    }

    const getPlayerName = playerId => {
        const entry = Object.entries(players).find(([key, value]) => value === playerId);
        return entry ? entry[0] : null;
    };

    const checkIfPlayerIsDisabled = (playerId) => {
        return Object.values(selectedChoices).includes(playerId)
    }

    const createMatrixFromObject = (playerObject, allowedValues) => {
        if (playerObject === null || playerObject === undefined) return
        const matrix = [];
        for (let i = 0; i < 3; i++) {
            matrix[i] = [];
            for (let j = 0; j < 3; j++) {
                const key = `row_${i}_col_${j}`;
                const allowedValuesForCell = allowedValues[key]
                const playerId = playerObject[key];
                const rarePercentage = allowedValuesForCell[playerId]
                matrix[i][j] = playerId !== "" ? `${getPlayerName(playerId)}$$$${rarePercentage}` : "";
            }
        }
        return matrix;
    };

    const handleSelection = (playerId, playerName) => {
        handlers.decrement()
        const key = `row_${selectedCell[0]}_col_${selectedCell[1]}`
        const allowedValues = get(game, ['allowed_values', key], [])
        if (Object.keys(allowedValues).includes(playerId)) {
            const updatedGrid = [...grid]
            updatedGrid[selectedCell[0]][selectedCell[1]] = `${playerName}$$$${allowedValues[playerId]}`
            setGrid(updatedGrid)
            setSelectedChoices(prev => ({ ...prev, [key]: playerId }))
            closePlayerPicker()
            setGridScore(prevScore => prevScore + 1)
        } else {
            setPlayerSelectionError(true)
            setTimeout(() => setPlayerSelectionError(false), 3000)
            setIncorrectAttempts(prev => ({ ...prev, [key]: prev[key] + 1 }))
        }
    }

    const save = () => {
        if (gridNo === 0) return
        try {
            const data = {
                'grid_no': gridNo,
                'guesses_left': count,
                'selected_choices': selectedChoices,
                'incorrect_attempts_by_cell': incorrectAttempts,
            }
            if (count === 0 || gridScore === 9) {
                data['score'] = gridScore
                data['rarity_score'] = (9 - gridScore) * 100
            }
            const headers = {
                'user_id': !localStorage.getItem("userId") ? '' : localStorage.getItem("userId"),
                'google_id': !localStorage.getItem("gId") ? '' : localStorage.getItem("gId"),
                'format': activeTab
            }
            axiosInstance.post(`/save`, data, { headers })
        } catch (error) {
            throw Error('Error saving')
        }
    }

    React.useEffect(() => {
        const getPreviousGrids = async () => {
            try {
                const headers = {
                    'user_id': !localStorage.getItem("userId") ? '' : localStorage.getItem("userId"),
                    'google_id': !localStorage.getItem("gId") ? '' : localStorage.getItem("gId"),
                    'format': activeTab
                }
                const response = await axiosInstance.get(`/game-list`, { headers })
                setPreviousGrids(get(response, ['data', 'data'], []))
            } catch (error) {
                throw Error('Error fetching previous grid')
            }
        }
        const getGame = async () => {
            try {
                const headers = {
                    'user_id': !localStorage.getItem("userId") ? '' : localStorage.getItem("userId"),
                    'google_id': !localStorage.getItem("gId") ? '' : localStorage.getItem("gId"),
                    'format': activeTab
                }
                const response = await axiosInstance.get(`/game/${gridNo}`, { headers })
                setGame(get(response, ['data', 'data'], []))
                setGridNo(get(response, ['data', 'data', 'grid_no'], 0))
                handlers.set(get(response, ['data', 'data', 'guesses_left'], 12))
                if (!!get(response, ['data', 'data', 'selected_choices'], null)) {
                    setSelectedChoices(get(response, ['data', 'data', 'selected_choices'], null))
                    let tempScore = 0
                    Object.values(get(response, ['data', 'data', 'selected_choices'], null)).forEach(playerId => {
                        if (playerId !== "") tempScore += 1
                    })
                    setGridScore(tempScore)
                    setGrid(createMatrixFromObject(get(response, ['data', 'data', 'selected_choices'], null), get(response, ['data', 'data', 'allowed_values'], null)))
                } else {
                    setGridScore(0)
                    setSelectedChoices(INIT_SELECTED_CHOICES)
                    setGrid(createMatrixFromObject(INIT_SELECTED_CHOICES, get(response, ['data', 'data', 'allowed_values'], {})))
                }
                if (!!get(response, ['data', 'data', 'incorrect_attempts_by_cell'], null)) {
                    setIncorrectAttempts(get(response, ['data', 'data', 'incorrect_attempts_by_cell'], null))
                } else {
                    setIncorrectAttempts(INIT_INCORRECT_ATTEMPTS)
                }
                if (!localStorage.getItem("userId")) {
                    localStorage.setItem(
                        "userId",
                        get(response, ['data', 'data', 'user_id'])
                    );
                }
                setInstruction(
                    get(response, ['data', 'data', 'instructions', 'row_0_col_0'])
                );
                setIsLoadingGame(false)
                getPreviousGrids()
            } catch (error) {
                throw Error('Error fetching game')
            }
        }
        getGame()
        // eslint-disable-next-line
    }, [gridNo])

    React.useEffect(() => {
        save()
        // eslint-disable-next-line
    }, [selectedChoices, incorrectAttempts])

    React.useEffect(() => {
        if (count === 0 || gridScore === 9) {
            save()
            openSummary()
        }
        // eslint-disable-next-line
    }, [count, gridScore])

    React.useEffect(() => {
        if (activeTab === 'ipl') {
            setPlayers(IPL_PLAYERS)
        } else {
            setPlayers(T20_PLAYERS)
        }
        setGridNo(0)
    }, [activeTab])

    React.useEffect(() => {
        if (currentUser !== null) {
            localStorage.setItem("gId", currentUser.uid)
        }
    })

    if (isLoadingGame || game === null) {
        return (
            <Center>
                <Loader color="blue" type="dots" />
            </Center>
        )
    }

    return (
        <Center>
            <Stack>
                <Group justify='center' align='center'>
                    <Text span>{`#${gridNo}`}</Text>
                    <Text span>|</Text>
                    <Button
                        style={{ padding: 0 }}
                        variant="transparent"
                        onClick={openPreviousGridList}
                        size="compact-md">
                        Play More
                    </Button>
                </Group>
                <Text style={{ textAlign: 'center' }}>
                    Tap on a cell to start.
                </Text>
                {!isSmallScreen && <Group>
                    <Grid grid={grid} game={game} clickCell={clickCell} count={count} incorrectAttempts={incorrectAttempts} />
                    <Stack style={{ marginLeft: '1rem' }} align='center'>
                        <Text size="sm" fw={500} c='gray' span>Guesses Left</Text>
                        <Text size="4rem" fw={700} span>{count}</Text>
                        {count > 0 && gridScore !== 9 && <Button variant="filled" size='compact-xs' onClick={() => handlers.set(0)}>Give Up</Button>}
                        {(count === 0 || gridScore === 9) && <Button variant="filled" size='compact-xs' onClick={openSummary}>View Summary</Button>}
                    </Stack>
                </Group>}
                {isSmallScreen && <Stack>
                    <Grid grid={grid} game={game} clickCell={clickCell} count={count} incorrectAttempts={incorrectAttempts} />
                    <Stack style={{ marginLeft: '1rem' }} align='center'>
                        <Text size="sm" fw={500} c='gray' span>Guesses Left</Text>
                        <Text size="4rem" fw={700} span>{count}</Text>
                        {count > 0 && gridScore !== 9 && <Button variant="filled" size='compact-xs' onClick={() => handlers.set(0)}>Give Up</Button>}
                        {(count === 0 || gridScore === 9) && <Button variant="filled" size='compact-xs' onClick={openSummary}>View Summary</Button>}
                    </Stack>
                </Stack>}
                <Space h='md' />
                <Text style={{ textAlign: 'center' }}>
                    {`Note: Stats are of ${activeTab === 'ipl' ? 'IPL' : 'T20 World Cup'} Career.\nPlayers must have played at least 1 match for that team to qualify.`}
                </Text>
            </Stack>
            <Modal opened={playerPickerOpened} onClose={closePlayerPicker} withCloseButton={false}>
                <Text style={{ marginBottom: '1rem' }} size='md'>{get(game, ['instructions', `row_${selectedCell[0]}_col_${selectedCell[1]}`], '')}</Text>
                <Autocomplete
                    placeholder="Pick Player"
                    data={Object.keys(players).map(item => ({ 'value': item, 'disabled': checkIfPlayerIsDisabled(players[item]) }))}
                    onOptionSubmit={(value) => handleSelection(players[value], value)}
                />
                {
                    playerSelectionError &&
                    <Group gap='xs' style={{ marginTop: '0.5rem' }} align='center'>
                        <IconXboxX size={15} stroke={2} color='red' />
                        <Text size='xs'>Oops! Wrong Player</Text>
                    </Group>
                }
            </Modal>
            <Modal opened={summaryOpened} onClose={closeSummary}>
                <Summary
                    game={game}
                    grid={grid}
                    gridNo={gridNo}
                    gridScore={gridScore}
                    activeTab={activeTab}
                    allowedValues={get(game, ['allowed_values'])}
                    incorrectAttempts={incorrectAttempts} />
            </Modal>
            <Modal opened={previosGridListOpened} onClose={closePreviousGridList} withCloseButton={false}>
                <PreviousGrids
                    previousGrids={previousGrids}
                    currentGridNo={gridNo}
                    setGridNo={setGridNo}
                    closePreviousGridList={closePreviousGridList}
                    guessLeft={count} />
            </Modal>
        </Center>
    )
}

export default Main