import CSK from './assets/CSKoutline.png'
import RCB from './assets/RCBoutline.png'
import SRH from './assets/SRHoutline.png'
import MI from './assets/MIoutline.png'
import LSG from './assets/LSGoutline.png'
import RR from './assets/RRoutline.png'
import DC from './assets/DCoutline.png'
import GT from './assets/GToutline.png'
import KKR from './assets/KKRoutline.png'
import PBKS from './assets/PBKSoutline.png'
import HDC from './assets/HDCoutline.png'
import RSP from './assets/RSPoutline.png'
import KT from './assets/KToutline.png'
import GL from './assets/GLoutline.png'
import PWI from './assets/PWIoutline.png'
import NEPAL from './assets/nepal.png'
import NZ from './assets/new zealand.png'
import NAMIBIA from './assets/namibia.png'
import AU from './assets/australia.png'
import SL from './assets/sri lanka.png'
import BANGLADESH from './assets/bangladesh.png'
import ENG from './assets/england.png'
import PAK from './assets/pakistan.png'
import AFG from './assets/afghanistan.png'
import IRL from './assets/ireland.png'
import ZIM from './assets/zimbabwe.png'
import WI from './assets/west indies.png'
import RSA from './assets/south africa.png'
import IND from './assets/india.png'
import USA from './assets/usa.png'
import NETHERLANDS from './assets/Netherlands.svg'

export const TEAMS = {
    'Chennai Super Kings': {
        'name': 'Chennai Super Kings',
        'abbrievation': 'CSK',
        'tooltip': 'Chennai Super Kings (2008-24)',
        'logo': CSK
    },
    'Mumbai Indians': {
        'name': 'Mumbai Indians',
        'abbrievation': 'MI',
        'tooltip': 'Mumbai Indians (2008-24)',
        'logo': MI
    },
    'Kolkata Knight Riders': {
        'name': 'Kolkata Knight Riders',
        'abbrievation': 'KKR',
        'tooltip': 'Kolkata Knight Riders (2008-24)',
        'logo': KKR
    },
    'Royal Challengers Bangalore': {
        'name': 'Royal Challengers Bangalore',
        'abbrievation': 'RCB',
        'tooltip': 'Royal Challengers Bangalore (2008-24)',
        'logo': RCB
    },
    'Royal Challengers Bengaluru': {
        'name': 'Royal Challengers Bangalore',
        'abbrievation': 'RCB',
        'tooltip': 'Royal Challengers Bangalore (2008-24)',
        'logo': RCB
    },
    'Sunrisers Hyderabad': {
        'name': 'Sunrisers Hyderabad',
        'abbrievation': 'SRH',
        'tooltip': 'Sunrisers Hyderabad (2013-24)',
        'logo': SRH
    },
    'Delhi Capitals': {
        'name': 'Delhi Capitals',
        'abbrievation': 'DC',
        'tooltip': 'Delhi Capitals (2019-24)\nDelhi Daredevils (2008-18)',
        'logo': DC
    },
    'Punjab Kings': {
        'name': 'Punjab Kings',
        'abbrievation': 'PBKS',
        'tooltip': 'Punjab Kings (2021-24)\nKings XI Punjab (2008-20)',
        'logo': PBKS
    },
    'Deccan Chargers': {
        'name': 'Deccan Chargers',
        'abbrievation': 'DC',
        'tooltip': 'Deccan Chargers (2008-12)',
        'logo': HDC
    },
    'Rising Pune Supergiant': {
        'name': 'Rising Pune Supergiant',
        'abbrievation': 'RPS',
        'tooltip': 'Rising Pune Supergiant (2016-17)',
        'logo': RSP
    },
    'Pune Warriors India': {
        'name': 'Pune Warriors India',
        'abbrievation': 'PWI',
        'tooltip': 'Pune Warriors India (2011-13)',
        'logo': PWI
    },
    'Rajasthan Royals': {
        'name': 'Rajasthan Royals',
        'abbrievation': 'RR',
        'tooltip': 'Rajasthan Royals (2008-24)',
        'logo': RR
    },
    'Gujarat Lions': {
        'name': 'Gujarat Lions',
        'abbrievation': 'GL',
        'tooltip': 'Gujarat Lions (2016-17)',
        'logo': GL
    },
    'Gujarat Titans': {
        'name': 'Gujarat Titans',
        'abbrievation': 'GT',
        'tooltip': 'Gujarat Lions (2022-24)',
        'logo': GT
    },
    'Lucknow Super Giants': {
        'name': 'Lucknow Super Giants',
        'abbrievation': 'GT',
        'tooltip': 'Lucknow Super Giants (2022-24)',
        'logo': LSG
    },
    'Kochi Tuskers Kerala': {
        'name': 'Kochi Tuskers Kerala',
        'abbrievation': 'KT',
        'tooltip': 'Kochi Tuskers Kerala (2011)',
        'logo': KT
    },
    'Nepal': {
        'name': 'Nepal',
        'abbrievation': 'Nepal',
        'tooltip': 'Nepal Cricket Team',
        'logo': NEPAL,
        'country': true
    },
    'New Zealand': {
        'name': 'New Zealand',
        'abbrievation': 'New Zealand',
        'tooltip': 'New Zealand Cricket Team',
        'logo': NZ,
        'country': true
    },
    'Namibia': {
        'name': 'Namibia',
        'abbrievation': 'Namibia',
        'tooltip': 'Namibia Cricket Team',
        'logo': NAMIBIA,
        'country': true
    },
    'Australia': {
        'name': 'Australia',
        'abbrievation': 'Australia',
        'tooltip': 'Australia Cricket Team',
        'logo': AU,
        'country': true
    },
    'Sri Lanka': {
        'name': 'Sri Lanka',
        'abbrievation': 'Sri Lanka',
        'tooltip': 'Sri Lanka Cricket Team',
        'logo': SL,
        'country': true
    },
    'Bangladesh': {
        'name': 'Bangladesh',
        'abbrievation': 'Bangladesh',
        'tooltip': 'Bangladesh Cricket Team',
        'logo': BANGLADESH,
        'country': true
    },
    'England': {
        'name': 'England',
        'abbrievation': 'England',
        'tooltip': 'England Cricket Team',
        'logo': ENG,
        'country': true
    },
    'Pakistan': {
        'name': 'Pakistan',
        'abbrievation': 'Pakistan',
        'tooltip': 'Pakistan Cricket Team',
        'logo': PAK,
        'country': true
    },
    'Afghanistan': {
        'name': 'Afghanistan',
        'abbrievation': 'Afghanistan',
        'tooltip': 'Afghanistan Cricket Team',
        'logo': AFG,
        'country': true
    },
    'Ireland': {
        'name': 'Ireland',
        'abbrievation': 'Ireland',
        'tooltip': 'Ireland Cricket Team',
        'logo': IRL,
        'country': true
    },
    'Zimbabwe': {
        'name': 'Zimbabwe',
        'abbrievation': 'Zimbabwe',
        'tooltip': 'Zimbabwe Cricket Team',
        'logo': ZIM,
        'country': true
    },
    'West Indies': {
        'name': 'West Indies',
        'abbrievation': 'West Indies',
        'tooltip': 'West Indies Cricket Team',
        'logo': WI,
        'country': true
    },
    'South Africa': {
        'name': 'South Africa',
        'abbrievation': 'South Africa',
        'tooltip': 'South Africa Cricket Team',
        'logo': RSA,
        'country': true
    },
    'India': {
        'name': 'India',
        'abbrievation': 'India',
        'tooltip': 'India Cricket Team',
        'logo': IND,
        'country': true
    },
    'USA': {
        'name': 'USA',
        'abbrievation': 'USA',
        'tooltip': 'USA Cricket Team',
        'logo': USA,
        'country': true
    },
    'Netherlands': {
        'name': 'Netherlands',
        'abbrievation': 'Netherlands',
        'tooltip': 'Netherlands Cricket Team',
        'logo': NETHERLANDS,
        'country': true
    }
}