import React from "react";
import {
  Center,
  Stack,
  Title,
  Text,
  SimpleGrid,
  Box,
  Button,
  Space,
  Group,
} from "@mantine/core";
// import { BarChart } from '@mantine/charts';
import TeamCell from "./TeamCell";
import get from "lodash.get";
import BarChart from "./BarChart";
import { IconBrandGoogleFilled } from '@tabler/icons-react'
import { getToken } from "firebase/messaging";
import { axiosInstance } from "../axios";
import { messaging } from "../firebase";
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'
import { useAuth } from "../AuthContext"

const Summary = ({
  game,
  grid,
  gridScore,
  gridNo,
  allowedValues,
  incorrectAttempts,
  activeTab
}) => {

  const { currentUser } = useAuth();

  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      localStorage.setItem("gId", user.uid)
      const data = {
        'email': user.email,
        'phoneNumber': user.phoneNumber,
        'photoURL': user.photoURL,
        'displayName': user.displayName,
      }
      const headers = {
        'user_id': !localStorage.getItem("userId") ? '' : localStorage.getItem("userId"),
        'google_id': user.uid,
      }
      await axiosInstance.post('/register_google_id', data, { headers })
      setShowSignInContent(false)
    } catch (error) {
      console.error('Error during sign-in:', error);
    }
  };


  const [copyButtonText, setCopyButtonText] = React.useState(
    "Boast Your Score to Friends"
  );
  const [pushNotificationText, setPushNotificationText] = React.useState(
    "Remind Me To Play Tomorrow"
  );

  const [showSignInContent, setShowSignInContent] = React.useState(false);

  const openCommonPlayers = (playerIds, instruction) => {
    localStorage.setItem("cp_instruction", instruction);
    localStorage.setItem("cp_playerids", JSON.stringify(playerIds));

    const url = `${window.location.origin}/players?format=${activeTab}`;
    window.open(url, "_blank");
  };

  const generateCellOutput = (cell, rowIndex, columnIndex) => {
    const key = `row_${rowIndex}_col_${columnIndex}`;
    if (
      typeof cell === "string" &&
      cell.length > 0 &&
      incorrectAttempts[key] === 0
    ) {
      return "🟩";
    } else if (
      typeof cell === "string" &&
      cell.length > 0 &&
      incorrectAttempts[key] > 0
    ) {
      return "🟨";
    } else {
      return "⬜️";
    }
  };

  const generateIPLGridGameOutput = () => {
    let output = `🏏 Cric Grid Game | ${activeTab === 'ipl' ? 'IPL' : 'T20WC'} | Grid #${gridNo}\n\n`;
    output += `I Scored ${gridScore}/9, can you?\n\n`;
    grid.forEach((row, rowIndex) => {
      row.forEach((cell, columnIndex) => {
        output += generateCellOutput(cell, rowIndex, columnIndex);
      });
      output += "\n"; // New line at the end of each row
    });
    output += "\nI challenge you to play at:\nhttps://cricgrids.com/";
    return output;
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopyButtonText("Copied to Clipboard");
      })
      .catch((err) => {
        setCopyButtonText("Error in Copying");
      });
    setTimeout(() => setCopyButtonText("Boast Your Score to Friends"), 3000);
  };

  const copyAndShare = () => {
    const text = generateIPLGridGameOutput();
    copyToClipboard(text);
  };

  const getCellBackground = (cell, rowIndex, columnIndex) => {
    const key = `row_${rowIndex}_col_${columnIndex}`;
    if (cell !== "" && incorrectAttempts[key] > 0) {
      return "#e9d700";
    } else if (cell !== "" && incorrectAttempts[key] === 0) {
      return "#32CD32";
    } else {
      return "#dedede";
    }
  };

  const registerNotificationToken = (token) => {
    axiosInstance({
      method: 'post',
      url: `/register_token/${token}`,
      headers: {
        'user_id': !localStorage.getItem("userId") ? '' : localStorage.getItem("userId"),
        'google_id': !localStorage.getItem("gId") ? '' : localStorage.getItem("gId")
      }
    })
  }

  const requestNotificationPermission = () => {
    getToken(messaging, { vapidKey: 'BG8oeaA8ptyWFEAby58XpAy9jEk4wtXse8GYKjTKgRz8a0Bw2wJ4KiNnVxs-DjAT3FeuPd_d_F9wE0UNOFsc04k' }).then((currentToken) => {
      if (currentToken) {
        registerNotificationToken(currentToken)
        setPushNotificationText('Yay! Reminder set for tomorrow.')
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
        // ...
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // ...
    });
  };

  React.useEffect(() => {
    if (currentUser === null) setShowSignInContent(true)
    // eslint-disable-next-line
  }, [])

  if (showSignInContent) {
    return <Center>
      <Stack align="center" gap={"xs"}>
        <Title>Create a free account to access your stats and streaks</Title>
        <Text>Your stats will be linked to your account and will update wherever you play.</Text>
        <Stack>
          <Button
            w={'10rem'}
            variant="light"
            size="md"
            onClick={() => setShowSignInContent(false)}>
            {'Skip'}
          </Button>
          <Button
            w={'10rem'}
            variant="filled"
            size="md"
            onClick={signInWithGoogle}
            leftSection={<IconBrandGoogleFilled size={18} />} justify="center">
            {'Sign In'}
          </Button>
        </Stack>
      </Stack>
    </Center>
  }

  return (
    <Center>
      <Stack align="center" gap={"xs"}>
        <Title>Summary</Title>
        <Text fw={600}>Your Score</Text>
        <Text size="3rem" fw={700} c="blue">
          {gridScore}
        </Text>
        <SimpleGrid cols={3} spacing="3px" verticalSpacing="3px">
          {grid.map((row, rowIndex) => {
            return row.map((cell, cellIndex) => (
              <Box
                key={`row_${rowIndex}_col_${cellIndex}`}
                style={{
                  width: "4rem",
                  height: "4rem",
                  background: getCellBackground(cell, rowIndex, cellIndex),
                  borderRadius: "5px",
                }}
              ></Box>
            ));
          })}
        </SimpleGrid>
        {
          gridScore === 9 &&
          <Button variant="filled" size="compact-xs" onClick={copyAndShare}>
            {copyButtonText}
          </Button>
        }
        {
          ('Notification' in window) && Notification.permission === 'default' &&
          <Button variant="filled" disabled={pushNotificationText === 'Yay! Reminder set for tomorrow.'} size="compact-xs" onClick={requestNotificationPermission}>
            {pushNotificationText}
          </Button>
        }
        <Space h="md" />
        <Center>
          <Text fw={600}>Today's Stats</Text>
        </Center>
        <Group w="100%" align="center" justify="space-evenly">
          <Stack gap="xs">
            <Center>
              <Text span c="dimmed">
                Games Played
              </Text>
            </Center>
            <Center>
              <Text span size="2.5rem" fw={700} c="blue">
                {get(game, ["summary", "total_games"])}
              </Text>
            </Center>
          </Stack>
          <Stack gap="xs">
            <Center>
              <Text span c="dimmed">
                Average Score
              </Text>
            </Center>
            <Center>
              <Text span size="2.5rem" fw={700} c="blue">
                {Math.round(get(game, ["summary", "average_score"]) * 100) /
                  100}
              </Text>
            </Center>
          </Stack>
        </Group>
        <Space h="md" />
        <Center>
          <Text fw={600}>Possible Answers</Text>
        </Center>
        <SimpleGrid cols={4} spacing="3px">
          {[["", "", ""], ...grid].map((row, rowIndex) => {
            return ["", ...row].map((cell, cellIndex) => {
              if (cellIndex === 0 && rowIndex === 0)
                return (
                  <Center
                    key={`row_${rowIndex}_col_${cellIndex}`}
                    style={{
                      width: "4rem",
                      height: "4rem",
                      borderRadius: "5px",
                    }}
                  ></Center>
                );
              if (rowIndex === 0 && cellIndex === 1)
                return (
                  <TeamCell
                    key={`row_${rowIndex}_col_${cellIndex}`}
                    isSummary
                    team={get(game, ["column_criteria"])[0]}
                  />
                );
              if (rowIndex === 0 && cellIndex === 2)
                return (
                  <TeamCell
                    key={`row_${rowIndex}_col_${cellIndex}`}
                    isSummary
                    team={get(game, ["column_criteria"])[1]}
                  />
                );
              if (rowIndex === 0 && cellIndex === 3)
                return (
                  <TeamCell
                    key={`row_${rowIndex}_col_${cellIndex}`}
                    isSummary
                    team={get(game, ["column_criteria"])[2]}
                  />
                );
              if (rowIndex === 1 && cellIndex === 0)
                return (
                  <TeamCell
                    key={`row_${rowIndex}_col_${cellIndex}`} isSummary team={get(game, ["row_criteria"])[0]} />
                );
              if (rowIndex === 2 && cellIndex === 0)
                return (
                  <TeamCell
                    key={`row_${rowIndex}_col_${cellIndex}`} isSummary team={get(game, ["row_criteria"])[1]} />
                );
              if (rowIndex === 3 && cellIndex === 0)
                return (
                  <TeamCell
                    key={`row_${rowIndex}_col_${cellIndex}`} isSummary team={get(game, ["row_criteria"])[2]} />
                );
              return (
                <Center
                  key={`row_${rowIndex}_col_${cellIndex}`}
                  style={{
                    width: "4rem",
                    height: "4rem",
                    borderRadius: "5px",
                    background: "#dedede",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    openCommonPlayers(
                      Object.keys(
                        allowedValues[
                        `row_${rowIndex - 1}_col_${cellIndex - 1}`
                        ]
                      ),
                      get(game, [
                        "instructions",
                        `row_${rowIndex - 1}_col_${cellIndex - 1}`,
                      ])
                    )
                  }
                >
                  <Stack justify="space-evenly" align="center" gap={0}>
                    <Text span>
                      {
                        Object.keys(
                          allowedValues[
                          `row_${rowIndex - 1}_col_${cellIndex - 1}`
                          ]
                        ).length
                      }
                    </Text>
                    <Text span style={{ textAlign: "center" }} size="10px">
                      (Show)
                    </Text>
                  </Stack>
                </Center>
              );
            });
          })}
        </SimpleGrid>
        <Space h="md" />
        <Center>
          <Stack gap={"xs"} align="center">
            <Text fw={600} span>Player Scores</Text>
            <Text size="xs" span>
              {get(game, ["summary", "game_count_by_score", "9"])} Players got
              9/9 Score
            </Text>
          </Stack>
        </Center>
        <Space h="md" />
        <BarChart dataObject={get(game, ["summary", "game_count_by_score"])} />
      </Stack>
    </Center>
  );
};

export default Summary;
