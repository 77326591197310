import React from 'react';
import { Chart } from 'react-charts';
import { useMediaQuery } from '@mantine/hooks'

const BarChart = ({ dataObject }) => {

    const isSmallScreen = useMediaQuery('(max-width: 767px)');

    // Transform the data object into an array of { score, people } objects
    const data = [
        {
            label: 'Scores',
            data: Object.keys(dataObject).map(key => ({
                score: key,
                people: dataObject[key]
            }))
        }
    ];

    const primaryAxis = React.useMemo(
        () => ({
            getValue: datum => datum.score,
            type: 'ordinal',
            showGrid: false 
        }),
        []
    );

    const secondaryAxes = React.useMemo(
        () => [
            {
                getValue: datum => datum.people,
                showGrid: false 
            }
        ],
        []
    );

    const getSeriesStyle = React.useCallback(
        () => ({
            color: '#228be6', // Tomato color
        }),
        []
    );

    const getDatumStyle = React.useCallback(
        () => ({
            rectElement: {
                style: {
                    borderRadius: '100px'
                }
            }
        }),
        []
    );

    return (
        <div style={{ width: isSmallScreen ? '300px' : '350px', height: isSmallScreen ? '150px' : '200px' }}>
            <Chart
                options={{
                    data,
                    primaryAxis,
                    secondaryAxes,
                    tooltip: false,
                    dark: false,
                    primaryCursor: false,
                    secondaryCursor: false,
                    getSeriesStyle,
                    getDatumStyle
                }}
            />
        </div>
    );
};

export default BarChart;
