import React from 'react'
import { Text } from '@mantine/core'
import { useHover, useMediaQuery } from '@mantine/hooks'

const PlayerCell = ({ rowId, columnId, clickCell, value, count, incorrectAttempts }) => {

    const { hovered, ref } = useHover()

    const isSmallScreen = useMediaQuery('(max-width: 767px)');

    const getCellBackground = () => {
        const key = `row_${rowId}_col_${columnId}`;
        if (value !== '' && incorrectAttempts[key] > 0) {
            return '#fffee0';
        }
        else if (value !== '' && incorrectAttempts[key] === 0) {
            return '#e6ffe6';
        }
        else {
            if (hovered && count > 0) {
                return '#dedede';
            }
            return 'white';
        }
    }

    return (
        <div
            onClick={() => clickCell(rowId, columnId)}
            ref={ref}
            style={{
                width: isSmallScreen ? '6rem' : '10rem',
                height: isSmallScreen ? '6rem' : '10rem',
                background: getCellBackground(),
                cursor: value !== '' || count === 0 ? 'default' : 'pointer',
                border: '1px solid #f2f2f2',
                borderTopLeftRadius: rowId === 0 && columnId === 0 ? '10px 10px': '0',
                borderTopRightRadius: rowId === 0 && columnId === 2 ? '10px 10px': '0',
                borderBottomLeftRadius: rowId === 2 && columnId === 0 ? '10px 10px': '0',
                borderBottomRightRadius: rowId === 2 && columnId === 2 ? '10px 10px': '0',
                position: 'relative'
            }}>
            {
                value.length > 0 && <Text
                    lineClamp={2}
                    fw={500}
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        textAlign: 'center'
                    }}>
                    {value.split('$$$')[0]}
                </Text>
            }
            {/* {
                value.length > 0 && <Text
                    size='0.75rem'
                    style={{
                        position: 'absolute',
                        bottom: '5', right: '5',
                        textAlign: 'center',
                        padding: '0.2rem',
                        background: '#ececec',
                        borderTopLeftRadius: '3px'
                    }}>
                    {value.split('$$$')[1]}
                </Text>
            } */}
        </div>
    )
}

export default PlayerCell
