import React from 'react'
import get from 'lodash.get';
import { Center, SimpleGrid} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'


import TeamCell from './TeamCell';
import PlayerCell from './PlayerCell';

const Grid = ({grid, game, clickCell, count, incorrectAttempts}) => {

    const isSmallScreen = useMediaQuery('(max-width: 767px)');

    return (
        <SimpleGrid cols={4} spacing="0" verticalSpacing="0">
            <Center style={{
                width: isSmallScreen ? '6rem': '10rem',
                height: isSmallScreen ? '6rem': '10rem',
                // border: '1px solid #f2f2f2'
            }} />
            <TeamCell team={get(game, ['column_criteria'])[0]} />
            <TeamCell team={get(game, ['column_criteria'])[1]} />
            <TeamCell team={get(game, ['column_criteria'])[2]} />
            <TeamCell team={get(game, ['row_criteria'])[0]} />
            <PlayerCell rowId={0} columnId={0} clickCell={clickCell} value={grid[0][0]} count={count} incorrectAttempts={incorrectAttempts}/>
            <PlayerCell rowId={0} columnId={1} clickCell={clickCell} value={grid[0][1]} count={count} incorrectAttempts={incorrectAttempts}/>
            <PlayerCell rowId={0} columnId={2} clickCell={clickCell} value={grid[0][2]} count={count} incorrectAttempts={incorrectAttempts}/>
            <TeamCell team={get(game, ['row_criteria'])[1]} /> 
            <PlayerCell rowId={1} columnId={0} clickCell={clickCell} value={grid[1][0]} count={count} incorrectAttempts={incorrectAttempts}/>
            <PlayerCell rowId={1} columnId={1} clickCell={clickCell} value={grid[1][1]} count={count} incorrectAttempts={incorrectAttempts}/>
            <PlayerCell rowId={1} columnId={2} clickCell={clickCell} value={grid[1][2]} count={count} incorrectAttempts={incorrectAttempts}/>
            <TeamCell team={get(game, ['row_criteria'])[2]} />
            <PlayerCell rowId={2} columnId={0} clickCell={clickCell} value={grid[2][0]} count={count} incorrectAttempts={incorrectAttempts}/>
            <PlayerCell rowId={2} columnId={1} clickCell={clickCell} value={grid[2][1]} count={count} incorrectAttempts={incorrectAttempts}/>
            <PlayerCell rowId={2} columnId={2} clickCell={clickCell} value={grid[2][2]} count={count} incorrectAttempts={incorrectAttempts}/>
        </SimpleGrid>
    )
}

export default Grid
