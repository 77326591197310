import React from 'react';
import { Group, Text, Image, Modal, Title, Space, Center, Tabs, Button } from '@mantine/core';
import { useMediaQuery, useDisclosure } from '@mantine/hooks'
import { useLocation } from 'react-router-dom'

import Logo from '../assets/logo.svg'

const Header = ({ instruction = '', removeHelp = false, activeTab = null, setActiveTab = () => { } }) => {

  const location = useLocation();

  const firstUpdateRef = React.useRef(false)

  const isSmallScreen = useMediaQuery('(max-width: 767px)');

  const [opened, { open, close }] = useDisclosure(false);

  React.useEffect(() => {
    let shownInstructions = false
    if (localStorage.getItem('instructionShown') !== null && localStorage.getItem('instructionShown') === 'true') {
      shownInstructions = true
    }
    if (!firstUpdateRef.current && !location.pathname.includes('players') && instruction.length > 0 && !shownInstructions) {
      open()
      firstUpdateRef.current = true
      localStorage.setItem('instructionShown', true)
    }
    // eslint-disable-next-line
  }, [instruction])

  return (
    <>
      <Group h="100%" wrap='nowrap' px="md" justify='space-between'>
        <Group align='center'>
          <Image width={isSmallScreen ? '35rem' : '50rem'} height={isSmallScreen ? '35rem' : '50rem'} src={Logo} style={{ borderRadius: '10px' }} />
          <Text style={{ cursor: 'default' }} fw={700} size="md" span>Cric Grid Game</Text>
        </Group>
        <Group align='center'>
          {
            !isSmallScreen && <Tabs value={activeTab} onChange={setActiveTab} >
              <Tabs.List>
                <Tabs.Tab value="ipl">IPL</Tabs.Tab>
                <Tabs.Tab value="t20wc">T20 World Cup</Tabs.Tab>
              </Tabs.List>
            </Tabs>
          }
          {!removeHelp &&
            <Button
              style={{ padding: 0 }}
              variant="transparent"
              onClick={open}
              size="compact-md">
              Help
            </Button>}
        </Group>
      </Group>
      <Modal opened={opened} onClose={close} size="auto" >
        <Center><Title>How to Play</Title></Center>
        <Space h="md" />
        <Center><Text style={{ textAlign: 'center' }}>Show off your smarts and become the ultimate cricket trivia champion!</Text></Center>
        <Space h="xl" />
        <Text fw={700}>Instructions</Text>
        <Space h="xs" />
        <Text>► Select a player for each cell that matches the criteria for that cell’s row and column.</Text>
        <Space h="sm" />
        {instruction.length > 0 && <Text>► For example, in today's grid, for 1st cell, you need to select a {instruction}</Text>}
        {instruction.length === 0 && <Text>► Criteria can be anything like an IPL team or some statistic.</Text>}
        <Space h="sm" />
        <Text>► You have 12 guesses to fill out the grid.</Text>
        <Space h="sm" />
        <Text>► Each guess, whether correct or incorrect, counts as a guess.</Text>
        <Space h="sm" />
        <Text>► A player cannot be used twice.</Text>
        <Space h="sm" />
        <Text>► There is a new grid everyday.</Text>
      </Modal>
    </>
  )
}

export default Header