import React from 'react'
import { Center, Tooltip, Image, Text } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

import { TEAMS } from '../Teams';
import get from 'lodash.get';

const TeamCell = ({ team, isSummary = false }) => {

  const isSmallScreen = useMediaQuery('(max-width: 767px)');

  const teamDetails = get(TEAMS, [team])

  return (
    <Center style={{
      width: isSummary ? '4rem' : isSmallScreen ? '6rem' : '10rem',
      height: isSummary ? '4rem' : isSmallScreen ? '6rem' : '10rem',
      padding: '0.5rem',
      display: 'flex',
      flexDirection: 'column'
      // border: '1px solid #f2f2f2'
    }}>
      {!!teamDetails && <Tooltip label={teamDetails['tooltip']}>
        <Image
          w={isSummary? '100%' : '80%'}
          h={isSummary? '100%' : '80%'}
          src={teamDetails['logo']}
          fit='contain'
        />
      </Tooltip>}
      {get(teamDetails, ['country'], false) && <Text style={{ textAlign: 'center', marginTop: '0.2rem' }} size={isSmallScreen || isSummary ? '0.75rem' : '1rem'} fw={600}>{team}</Text>}
      {!teamDetails && <Text style={{ textAlign: 'center' }} size={isSmallScreen || isSummary ? 'sm' : 'xl'} fw={600}>{team}</Text>}
    </Center >
  )
}

export default TeamCell
