import { Stack, Image, Space, Text, Group, Button, Container, AppShell, Modal, Center, Title } from '@mantine/core'
import React, { useEffect } from 'react'
import { useMediaQuery, useDisclosure } from '@mantine/hooks'
import { IconBrandGoogleFilled } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'

import Logo from '../assets/logo.svg'
import { useAuth } from '../AuthContext'
import { axiosInstance } from '../axios'

const InitScreen = () => {

    const { currentUser } = useAuth();

    const isSmallScreen = useMediaQuery('(max-width: 767px)');

    const [opened, { open, close }] = useDisclosure(false);

    const navigate = useNavigate()

    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    const signInWithGoogle = async () => {
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            localStorage.setItem("gId", user.uid)
            const data = {
                'email': user.email,
                'phoneNumber': user.phoneNumber,
                'photoURL': user.photoURL,
                'displayName': user.displayName,
            }
            const headers = {
                'user_id': !localStorage.getItem("userId") ? '' : localStorage.getItem("userId"),
                'google_id': user.uid,
            }
            await axiosInstance.post('/register_google_id', data, { headers })
            // navigate('/game')
        } catch (error) {
            console.error('Error during sign-in:', error);
        }
    };

    useEffect(() => {
        if (currentUser !== null) {
            localStorage.setItem("gId", currentUser.uid)
            navigate('/game')
        }
    })

    return (
        <AppShell>
            <AppShell.Main bg={'#F7F7F7'}>
                <Container>
                    <Stack align='center'>
                        <Space h="xl" />
                        <Space h="xl" />
                        <Space h="xl" />
                        <Image
                            radius="md"
                            h={!isSmallScreen ? 150 : 100}
                            w={!isSmallScreen ? 150 : 100}
                            fit="contain"
                            src={Logo}
                        />
                        <Text size={!isSmallScreen ? "3rem" : '2rem'} fw={500} >Cric Grid Game</Text>
                        <Text c="dimmed" size={!isSmallScreen ? "1.5rem" : '1.2rem'} style={{ textAlign: 'center' }}>Grid Glory Awaits: Guess All 9 and Share Your Triumph!</Text>
                        <Space h="xs" />
                        {
                            !isSmallScreen &&
                            <Group grow='true'>
                                <Button
                                    w={'10rem'}
                                    variant="light"
                                    size="md"
                                    onClick={open}>
                                    {'How to Play'}
                                </Button>
                                <Button
                                    w={'10rem'}
                                    variant="light"
                                    size="md"
                                    onClick={signInWithGoogle}
                                    leftSection={<IconBrandGoogleFilled size={18} />} justify="center">
                                    {'Sign In'}
                                </Button>
                                <Button
                                    w={'10rem'}
                                    variant="filled"
                                    size="md"
                                    onClick={() => navigate('/game')}>
                                    {'Play'}
                                </Button>
                            </Group>
                        }
                        {
                            isSmallScreen &&
                            <Stack grow='true'>
                                <Button
                                    w={'10rem'}
                                    variant="light"
                                    size="md"
                                    onClick={open}>
                                    {'How to Play'}
                                </Button>
                                <Button
                                    w={'10rem'}
                                    variant="light"
                                    size="md"
                                    onClick={signInWithGoogle}
                                    leftSection={<IconBrandGoogleFilled size={18} />} justify="center">
                                    {'Sign In'}
                                </Button>
                                <Button
                                    w={'10rem'}
                                    variant="filled"
                                    size="md"
                                    onClick={() => navigate('/game')}>
                                    {'Play'}
                                </Button>
                            </Stack>
                        }
                    </Stack>
                </Container>
                <Modal opened={opened} onClose={close} size="auto" >
                    <Center><Title>How to Play</Title></Center>
                    <Space h="md" />
                    <Center><Text style={{ textAlign: 'center' }}>Show off your smarts and become the ultimate cricket trivia champion!</Text></Center>
                    <Space h="xl" />
                    <Text fw={700}>Instructions</Text>
                    <Space h="xs" />
                    <Text>► Select a player for each cell that matches the criteria for that cell’s row and column.</Text>
                    <Space h="sm" />
                    <Text>► Criteria can be anything like an team or some statistic.</Text>
                    <Space h="sm" />
                    <Text>► You have 12 guesses to fill out the grid.</Text>
                    <Space h="sm" />
                    <Text>► Each guess, whether correct or incorrect, counts as a guess.</Text>
                    <Space h="sm" />
                    <Text>► A player cannot be used twice.</Text>
                    <Space h="sm" />
                    <Text>► There is a new grid everyday.</Text>
                </Modal>
            </AppShell.Main>
        </AppShell>
    )
}

export default InitScreen
