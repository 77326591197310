import React from 'react'
import { Table, Text } from '@mantine/core'
import { useHover } from '@mantine/hooks'

const PreviousGridRow = ({previousGrid, setGridNo, closePreviousGridList, currentGridNo, guessLeft}) => {

    const displayStatus = (status, gridNo) => {
        if (gridNo === currentGridNo && guessLeft > 0) return 'Continue Playing'
        if (status === 'complete') return 'Complete'
        else if (status === 'incomplete') return 'Continue Playing'
        else if (status === 'not_started') return 'Play Now!'
    }

    const { hovered, ref } = useHover()

    return (
        <Table.Tr ref={ref} style={{ cursor: 'pointer', background: hovered ? '#f5f5f5' : 'white' }} key={previousGrid.grid_no} onClick={() => {
            closePreviousGridList()
            setGridNo(previousGrid.grid_no)
        }}>
            <Table.Td>{previousGrid.grid_no}</Table.Td>
            <Table.Td>{previousGrid.date}</Table.Td>
            <Table.Td>{previousGrid.status === 'complete' ? previousGrid.score + ' / 9' : ''}</Table.Td>
            <Table.Td><Text size='xs' color='blue'>{displayStatus(previousGrid.status, previousGrid.grid_no)}</Text></Table.Td>
        </Table.Tr>
    )
}

export default PreviousGridRow
