import React from 'react'
import { Center, Stack, Title, Table } from '@mantine/core'
import PreviousGridRow from './PreviousGridRow'

const PreviousGrids = ({ previousGrids, currentGridNo, setGridNo, closePreviousGridList, guessLeft }) => {

    const rows = previousGrids.map((previousGrid, index) => (
        <PreviousGridRow
            key={index}
            previousGrid={previousGrid}
            setGridNo={setGridNo}
            closePreviousGridList={closePreviousGridList}
            currentGridNo={currentGridNo}
            guessLeft={guessLeft} />
    ));

    return (
        <Center>
            <Stack align='center' gap={'xs'}>
                <Title>Archive</Title>
                <Table stickyHeader>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>Grid</Table.Th>
                            <Table.Th>Date</Table.Th>
                            <Table.Th>Score</Table.Th>
                            <Table.Th>Status</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>{rows}</Table.Tbody>
                </Table>
            </Stack>
        </Center>
    )
}

export default PreviousGrids
