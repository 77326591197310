import React, { useEffect } from 'react'
import { useAuth } from '../AuthContext'
import { useNavigate } from 'react-router-dom'

const Logout = () => {

    const { currentUser, logout } = useAuth();
    const navigate = useNavigate()

    useEffect(() => {
        if (currentUser !== null) {
            logout()
        }    
        navigate('/')
        // eslint-disable-next-line
    }, [])

    return (
        <div>

        </div>
    )
}

export default Logout
